import {Controller, useForm} from "react-hook-form";
import {Button, Input, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import FormHelper from "../../helpers/form";
import {useEffect, useState} from "react";
import Api from 'api/standart-salary'
import Select from "react-select";
import Employees from "../../api/employees";

const Add = ({fetchData, form, setForm, months, years, values}) => {
    const currentDate = new Date()
    const {control, handleSubmit, setValue, setError, formState: {errors}} = useForm({
        defaultValues: {
            month: months.find(item => item.value === currentDate.getMonth() + 1),
            year: {
                value: currentDate.getFullYear(),
                label: currentDate.getFullYear()
            }
        }
    })
    const [loader, setLoader] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const data = form?.data
    const [workers, setWorkers] = useState([])

    const submit = async values => {
        setLoader(true)
        try {
            await Api[values?.id ? 'update' : 'add'](FormHelper.validateBody(values))
            fetchData()
            setForm({})
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchWorkers = async () => {
        setIsFetching(true)
        const {data} = await Employees.getSelect()
        setWorkers(data)
        setIsFetching(false)
    }

    useEffect(() => {
        fetchWorkers()
    }, []);

    useEffect(() => {
        if (data && workers.length) {
            setValue('id', data.id)
            // setValue('worker_id', workers.find(item => item.value == data?.worker_id))
            setValue('amount', data?.payment)
            setValue('month', months.find(item => item.value === values?.month?.value))
            setValue('year', years.find(item => item.value === values?.year?.value))
        }
    }, [form, workers])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {isFetching ? <div className="d-flex justify-content-center">
                    <Spinner size="lg" color="primary"/>
                </div> : (
                    <>
                        {!data && (
                            <div className="mb-3">
                                <Label for="worker_id">İşçi</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Select
                                        options={workers}
                                        placeholder=""
                                        className={`w-100 ${errors?.worker_id && 'is-invalid'}`}
                                        onChange={onChange}
                                        value={value}
                                        name="worker_id"
                                        id="worker_id"/>
                                )} name="worker_id" control={control}/>
                            </div>
                        )}
                        <div className="mb-3">
                            <Label for="amount">Məbləğ</Label>
                            <Controller rules={{required: true}} name="amount" control={control}
                                        render={({field: {value, onChange}}) => (
                                            <Input
                                                name="amount"
                                                id="amount"
                                                value={value}
                                                onChange={onChange}
                                                className={errors?.amount && 'is-invalid'}
                                            />
                                        )}/>
                            {FormHelper.generateFormFeedback(errors, 'amount')}
                        </div>
                        <div className="mb-3 w-100">
                            <Label for="month">Ay</Label>
                            <Controller name="month"
                                        control={control}
                                        render={({field: {value, onChange}}) => (
                                            <Select
                                                isDisabled={data}
                                                isClearable
                                                options={months}
                                                placeholder=""
                                                onChange={onChange}
                                                value={value}
                                                name="month"
                                                id="month"/>
                                        )}/>
                        </div>
                        <div className="mb-3 w-100">
                            <Label for="year">İl</Label>
                            <Controller name="year"
                                        control={control}
                                        render={({field: {value, onChange}}) => (
                                            <Select
                                                isDisabled={data}
                                                isClearable
                                                options={years}
                                                placeholder=""
                                                onChange={onChange}
                                                value={value}
                                                name="year"
                                                id="year"/>
                                        )}/>
                        </div>
                        <div className="mb-3">
                            <Label for="note">Qeyd</Label>
                            <Controller name="note" control={control}
                                        render={({field: {value, onChange}}) => (
                                            <Input
                                                type="textarea"
                                                rows={5}
                                                name="note"
                                                id="note"
                                                value={value}
                                                onChange={onChange}
                                                className={errors?.note && 'is-invalid'}
                                            />
                                        )}/>
                            {FormHelper.generateFormFeedback(errors, 'note')}
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Yadda saxla'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default Add
