import Breadcrumbs from "components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import Api from 'api/standart-salary'

import {Badge, Button, Card, CardBody, Col, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import CustomPagination from "../../components/CustomPagination";
import {useForm} from "react-hook-form";
import Filters from "./Filters";
import Form from "../../helpers/form";
import Can from "../../components/Common/Can";
import DetailModal from "./DetailModal";
import Add from "./Add";


const selectElement = (value, label) => {
    return {value, label}
}

const months = [
    selectElement(1, 'Yanvar'),
    selectElement(2, 'Fevral'),
    selectElement(3, 'Mart'),
    selectElement(4, 'Aprel'),
    selectElement(5, 'May'),
    selectElement(6, 'Iyun'),
    selectElement(7, 'Iyul'),
    selectElement(8, 'Avqust'),
    selectElement(9, 'Sentyabr'),
    selectElement(10, 'Oktyabr'),
    selectElement(11, 'Noyabr'),
    selectElement(12, 'Dekabr'),
]

const years = []
const year = new Date().getFullYear()
Array.from({length: 20}).forEach((item, index) => {
    years.unshift({
        value: year - index - 1,
        label: year - index - 1
    })
})

years.push({
    value: year,
    label: year
})

const Salaries = () => {
    document.title = 'Standart maaşlar'
    const currentDate = new Date()
    const [data, setData] = useState([])
    const [form, setForm] = useState(null)
    const defaultValues = {
        worker_id: null,
        month: months.find(item => item.value === currentDate.getMonth() + 1),
        year: {
            value: currentDate.getFullYear(),
            label: currentDate.getFullYear()
        }
    }
    const filterForm = useForm({defaultValues})
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [isFetching, setIsFetching] = useState(true)
    const [detailModal, setDetailModal] = useState(false)

    const fetchData = async (showLoader = true, p = null) => {
        if (p) {
            setPage(p)
        }
        setIsFetching(showLoader)
        const values = filterForm.getValues()
        const data = await Api.get({
            page: p || page,
            ...Form.validateBody(values),
        })
        setData(data?.data)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <div className="page-content">
            <Modal className="modal-dialog-centered" isOpen={form?.status}
                   toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>Əlavə et</ModalHeader>
                <Add form={form} setForm={setForm} years={years} months={months} fetchData={fetchData}
                     values={filterForm.getValues()}/>
            </Modal>
            <Modal className="modal-dialog-centered" isOpen={detailModal?.status}
                   toggle={() => setDetailModal({})}>
                <ModalHeader
                    toggle={() => setDetailModal({})}>Bax</ModalHeader>
                <DetailModal form={detailModal} setForm={setDetailModal} setEditForm={setForm} fetchData={fetchData}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`STANDART MAAŞLAR (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <Filters months={months} years={years} form={filterForm} fetchData={fetchData}
                                 defaultValues={defaultValues}/>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg"/>
                                </div>
                            ) : (
                                <CardBody>
                                    <Can action="standart_salary_add">
                                        <Button
                                            onClick={() => setForm({status: true})}
                                            type="button"
                                            color="success"
                                            className="btn-label mb-3"
                                        >
                                            <i className="bx bx-plus label-icon"/> Əlavə et
                                        </Button>
                                    </Can>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Ad soyad</th>
                                                <th>Maaş</th>
                                                <th>Ödənilən</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name} {item.surname}</td>
                                                    <td>
                                                        <Badge color="warning">
                                                            {item.amount}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge color="success">
                                                            {item.payments_sum_payment || 0}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <Button color="primary"
                                                                    id={`show-${item.id}`}
                                                                    onClick={() => setDetailModal({
                                                                        status: true,
                                                                        data: item
                                                                    })}>
                                                                <i className="bx bx-show"/>
                                                            </Button>
                                                            <UncontrolledTooltip target={`show-${item.id}`}
                                                                                 placement="bottom">
                                                                Bax
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <CustomPagination total={total} setPage={setPage} page={page}/>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Salaries
