import {Button, ModalBody, ModalFooter, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import Can from "../../components/Common/Can";
import ConfirmModal from "../../components/Common/ConfirmModal";
import {useState} from "react";
import Api from "../../api/standart-salary";

const DetailModal = ({setForm, form, setEditForm, fetchData}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const data = form?.data

    const deleteData = async () => {
        await Api.delete(confirmModal)
        setForm()
        fetchData()
    }
    return (
        <>
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <ModalBody>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Ödəniş</th>
                            <th>Tarix</th>
                            <th>Qeyd</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.payments?.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.payment}</td>
                                <td>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
                                <td>{item?.note}</td>
                                <td>
                                    <div className="d-flex align-items-center gap-1">
                                        <Can action="standart_salary_edit">
                                            <Button color="success"
                                                    id={`edit-${item.id}`}
                                                    onClick={() => {
                                                        setForm(null)
                                                        setEditForm({
                                                            status: true,
                                                            data: item
                                                        })
                                                    }}>
                                                <i className="bx bx-pencil"/>
                                            </Button>
                                            <UncontrolledTooltip target={`edit-${item.id}`}
                                                                 placement="bottom">
                                                Düzəliş et
                                            </UncontrolledTooltip>
                                        </Can>
                                        <Can action="standart_salary_delete">
                                            <Button color="danger"
                                                    id={`delete-${item.id}`}
                                                    onClick={() => {
                                                        setConfirmModal(item.id)
                                                    }}>
                                                <i className="bx bx-trash"/>
                                            </Button>
                                            <UncontrolledTooltip target={`delete-${item.id}`}
                                                                 placement="bottom">
                                                Sil
                                            </UncontrolledTooltip>
                                        </Can>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Bağla</Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default DetailModal