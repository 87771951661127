import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import {useEffect, useState} from "react";
import Api from 'api/sale'

import {Button, Card, CardBody, Col, Label, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import Add from "./Add";
import CustomPagination from "../../components/CustomPagination";
import Can from "../../components/Common/Can";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import FlatPicker from "react-flatpickr";
import Employees from "../../api/employees";
import Form from "../../helpers/form";

const Sale = () => {
    document.title = 'Məxaric'
    const [confirmModal, setConfirmModal] = useState(false)
    const [form, setForm] = useState({})
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [isFetching, setIsFetching] = useState(true)
    const [workers, setWorkers] = useState([])
    const [doctors, setDoctors] = useState([])
    const {control, getValues, handleSubmit, errors, reset} = useForm()

    const deleteData = async () => {
        await Api.delete(confirmModal)
        fetchData()
    }

    const fetchData = async (showLoader = true, p = null) => {
        setIsFetching(showLoader)
        const data = await Api.get({
            page: p || page, ...Form.validateBody(getValues()),
            date: Form.convertFormDate(getValues()?.date)
        })
        setData(data?.data?.transactions)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    const fetchWorkers = async () => {
        const {data} = await Employees.getSelect()
        setWorkers(data)
    }

    const fetchDoctors = async () => {
        const {data} = await Employees.getDoctors()
        setDoctors(data)
    }


    const filter = () => {
        fetchData(true, 1)
    }

    useEffect(() => {
        fetchWorkers()
        fetchDoctors()
    }, [])

    useEffect(() => {
        fetchData()
    }, [page])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <Modal size="lg" className="modal-dialog-centered" isOpen={form?.status}
                   toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>{form?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <Add fetchData={fetchData} form={form} setForm={setForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`MƏXARİC (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <form onSubmit={handleSubmit(filter)}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <div className="mb-3">
                                                <Label for="worker_id">İşçi</Label>
                                                <Controller name="worker_id"
                                                            control={control}
                                                            render={({field: {value, onChange}}) => (
                                                                <Select
                                                                    isClearable={true}
                                                                    options={workers}
                                                                    placeholder=""
                                                                    className={`w-100 ${errors?.worker_id && 'is-invalid'}`}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="worker_id"
                                                                    id="worker_id"/>
                                                            )}/>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div className="mb-3">
                                                <Label for="doctor_id">Həkim</Label>
                                                <Controller name="doctor_id"
                                                            control={control}
                                                            render={({field: {value, onChange}}) => (
                                                                <Select
                                                                    isClearable={true}
                                                                    options={doctors}
                                                                    placeholder=""
                                                                    className={`w-100 ${errors?.doctor_id && 'is-invalid'}`}
                                                                    onChange={onChange}
                                                                    value={value}
                                                                    name="doctor_id"
                                                                    id="doctor_id"/>
                                                            )}/>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <div className="mb-3">
                                                <Label for="date">Tarix</Label>
                                                <Controller name="date"
                                                            control={control}
                                                            render={({field: {value, onChange}}) => (
                                                                <FlatPicker
                                                                    className="form-control d-block"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    options={{
                                                                        locale: 'az'
                                                                    }}
                                                                />
                                                            )}/>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <Button id="reset-btn" color="primary" outline onClick={() => {
                                                    reset({
                                                        worker_id: null,
                                                        doctor_id: null,
                                                        date: null,
                                                    })
                                                    setPage(1)
                                                    fetchData(true, 1)
                                                }}>
                                                    <i className="bx bx-rotate-right"/>
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target="reset-btn">
                                                    Sıfırla
                                                </UncontrolledTooltip>
                                                <Button color="primary" type="submit">Axtar</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </form>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg"/>
                                </div>
                            ) : (
                                <CardBody>
                                    <Can action="sale_add">
                                        <Button
                                            onClick={() => setForm({status: true})}
                                            type="button"
                                            color="success"
                                            className="btn-label mb-3"
                                        >
                                            <i className="bx bx-plus label-icon"/> Əlavə et
                                        </Button>
                                    </Can>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>№</th>
                                                <th>Anbardar</th>
                                                <th>Şöbə</th>
                                                <th>Götürən işçi</th>
                                                <th>Götürən həkim</th>
                                                <th>Tarix</th>
                                                <th>Qeyd</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.storehouser_name} {item?.storehouser_surname}</td>
                                                    <td>{item.department_name}</td>
                                                    <td>{item.worker_name} {item.worker_surname}</td>
                                                    <td>{item.doctor_name} {item.doctor_surname}</td>
                                                    <td>{item?.created_at && moment(item.created_at).format('DD.MM.YYYY')}</td>
                                                    <td>{item.note}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <Can action="sale_edit">
                                                                <Button color="success"
                                                                        id={`edit-${item.id}`}
                                                                        onClick={() => setForm({
                                                                            status: true,
                                                                            data: item
                                                                        })}>
                                                                    <i className="bx bx-pencil"/>
                                                                </Button>
                                                                <UncontrolledTooltip target={`edit-${item.id}`}
                                                                                     placement="bottom">
                                                                    Düzəliş et
                                                                </UncontrolledTooltip>
                                                            </Can>
                                                            <Can action="sale_delete">
                                                                <Button color="danger"
                                                                        id={`delete-${item.id}`}
                                                                        onClick={() => setConfirmModal(item.id)}>
                                                                    <i className="bx bx-trash"/>
                                                                </Button>
                                                                <UncontrolledTooltip target={`delete-${item.id}`}
                                                                                     placement="bottom">
                                                                    Sil
                                                                </UncontrolledTooltip>
                                                            </Can>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <CustomPagination total={total} setPage={setPage} page={page}/>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Sale
